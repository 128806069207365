import Layout from "@components/layout";
import React from "react";
import SEO from "@components/common/seo";
import { withStores } from "@helpers/hoc/withQueries";
import { navigate } from "gatsby";
import "@styles/global.styles.css";
import { token, components } from "@components/styles/global.styles";
import { Select, Form, Button, ConfigProvider } from "antd";
import { logout } from "@services/auth";
import logo from "@images/mainLogo.png";
import { URLS } from "@helpers/const";

type Props = {
  location?: {
    pathname: string;
  };
  stores: any;
};

const Home: React.FC<Props> = ({ stores }: Props) => {
  const [form] = Form.useForm();

  const options = stores?.stores?.items?.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const onFinish = (res: any) => {
    navigate(`${URLS.board}/${res.selection}`);
  };

  return (
    <ConfigProvider
      theme={{
        token,
        components,
      }}
    >
      <Layout>
        <SEO title="DLKUB" />
        <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-50">
          <img className="mb-6 w-96" src={logo} alt="dlkub-logo" />
          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            className="p-6 bg-white rounded-lg shadow-md w-96"
          >
            <Form.Item
              name="selection"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select Branch" className="w-full">
                {options?.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className="mb-0">
              <Button type="primary" htmlType="submit" className="w-full">
                Go to Branch
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={() => logout(() => navigate(URLS.login))}
            className="mt-4"
          >
            Logout
          </Button>
        </div>
      </Layout>
    </ConfigProvider>
  );
};

export default withStores(Home);
