import React from "react";
import { navigate } from "gatsby";
import { Router } from "@reach/router";
import { ConfigProvider } from "antd";
import PrivateRoute from "@helpers/hoc/privateRoute";

import Layout from "@components/layout";
import NotFound from "@pages/404";

import Home from "@views/home";
import Board from "@views/board";
import Access from "@views/access";
import { URLS, PREFIXED_URI, paths } from "@helpers/const";
import "@styles/global.styles.css";
import { token } from "@components/styles/global.styles";

type Props = {
  location?: {
    pathname: string;
  };
};

const App: React.FC<Props> = ({ location }: Props) => {
  if (
    location.pathname === PREFIXED_URI ||
    location.pathname === `${PREFIXED_URI}/`
  ) {
    if (typeof window !== "undefined") {
      navigate(URLS.home);
    }
    return null;
  }

  return (
    <ConfigProvider
      theme={{
        token,
      }}
    >
      <Layout>
        <Router basepath={PREFIXED_URI}>
          <Access path={paths.access} />
          <PrivateRoute
            path={paths.home}
            component={Home}
            location={location}
          />
          <PrivateRoute
            path={`${paths.board}/:boardId`}
            component={Board}
            location={location}
          />

          <NotFound default />
        </Router>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
