import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { message } from "antd";
import { useMutation } from "@apollo/client";
import SEO from "@components/common/seo";
import LoginForm from "@components/common/loginForm";
import { handleLogin, isLoggedIn } from "@services/auth";
import { mutation } from "@helpers/queryStrings";
import { URLS, messages } from "@helpers/const";
import withLocation from "@helpers/hoc/withLocation";
import logo from "@images/logo.png";

type SubmitLoginProps = {
  identifier: string;
  password: string;
};

type AccessProps = {
  search?: {
    auth: string;
    token: string;
    provider: string;
  };
  location: object;
};

const Access: React.FC<AccessProps> = ({ search: { auth } }) => {
  const isSelection = auth ? auth : "login";
  const [messageState, setMessageState] = useState(true);

  const [login, { loading: loginLoading }] = useMutation(mutation.login, {
    fetchPolicy: "network-only",
    awaitRefetchQueries: true,
    onCompleted: (res: object): void => {
      if (res) {
        handleLogin(res);
        navigate(URLS.home);
      }
    },
    onError: (err: object): void => {
      if (err) {
        if (messageState) {
          setMessageState(false);
          message.warning(messages.errors.login, () => {
            setMessageState(true);
          });
        }
      }
    },
  });

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(URLS.home);
    }
  }, []);

  return (
    <>
      <SEO title="Login" />
      <div className="p-14">
        <div
          className="w-24 h-24 mx-auto my-12 bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${logo})` }}
        />
        <LoginForm
          loading={loginLoading}
          isSelection={isSelection}
          submitLogin={(values: SubmitLoginProps) =>
            login({
              variables: {
                data: {
                  username: values.email,
                  password: values.password,
                },
              },
            })
          }
        />
      </div>
    </>
  );
};

export default withLocation(Access);
