// import React from "react";
// import "./global.styles.css";

const color = {
  primary_100: "#D65A31",
  primary_50: "#ff4d4f",
  white: "#FFFFFF",
};
export const token = {
  colorPrimary: color.primary_100,
  colorLink: color.primary_100,
  colorInfo: color.primary_100,
  colorInfoText: color.primary_100,
  colorPrimaryText: color.primary_100,
  colorBgLayout: color.white,
};

export const components = {
  Segmented: {
    itemSelectedColor: color.white,
    itemSelectedBg: color.primary_100,
  },
  Table: {
    headerBg: color.primary_100,
    headerColor: color.white,
    cellFontSize: "26px",
  },
};
