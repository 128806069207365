import React from "react";
import { Form, Input, Button } from "antd";
import { authSelection } from "@helpers/const";

type Props = {
  submitLogin?: (values: object) => void;
  loading: boolean;
  isSelection?: string;
};

const LoginForm: React.FC<Props> = ({
  submitLogin,
  loading,
  isSelection,
}: Props) => {
  const onFinish = (values: object) => {
    submitLogin(values);
  };

  return (
    <div className="flex flex-col items-center justify-center py-2">
      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md"
      >
        <Form.Item
          hasFeedback
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            placeholder="Email"
            className="w-full px-3 py-2 border rounded"
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password
            placeholder="Password"
            className="w-full px-3 py-2 border rounded"
          />
        </Form.Item>

        {!(isSelection === authSelection.login) && (
          <Form.Item
            hasFeedback
            name="c_password"
            rules={[
              {
                required: true,
                message: "Please input your Again Your Password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              className="w-full px-3 py-2 border rounded"
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full py-2"
            loading={loading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
