import React from "react";
import { useQuery } from "@apollo/client";
import { queries } from "@helpers/queryStrings";

export const withRates =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const { data, loading, refetch } = useQuery(queries.exchangeRates);

    return (
      <ComponentToWrap
        {...props}
        exchangeRates={data && data}
        loadingExchangeRates={loading}
        refetchExchangeRates={refetch}
      />
    );
  };

export const withStores =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const { data, loading, refetch } = useQuery(queries.stores);

    return (
      <ComponentToWrap
        {...props}
        stores={data && data}
        loadingStores={loading}
        refetchStores={refetch}
      />
    );
  };
