import { gql } from "@apollo/client";

export const queries = {
  exchangeRates: gql`
    query exchangeRates(
      $perPage: Int!
      $where: ExchangeRateWhereInput
      $orderBy: [ExchangeRateOrderByWithRelationInput!]
    ) {
      exchangeRates(perPage: $perPage, where: $where, orderBy: $orderBy) {
        items {
          id
          label
          buyRate
          sellRate
          denomination
          currency {
            name
            code
            status
          }
        }
      }
    }
  `,
  stores: gql`
    query stores {
      stores {
        items {
          id
          name
        }
      }
    }
  `,
};

export const mutation = {
  login: gql`
    mutation login($data: LoginInput!) {
      login(data: $data) {
        accessToken
      }
    }
  `,
};
