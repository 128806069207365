import Layout from "@components/layout";
import React, { useMemo, useEffect, useState } from "react";
import SEO from "@components/common/seo";
import "@styles/global.styles.css";
import { token, components } from "@components/styles/global.styles";
import { Button, ConfigProvider } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import logo from "@images/logo.png";
import { withRates } from "@helpers/hoc/withQueries";
import { useQuery } from "@apollo/client";
import { queries } from "@helpers/queryStrings";

type Props = {
  location?: object;
  boardId: string;
};

const Board: React.FC<Props> = ({ boardId }: Props) => {
  const [tableData, setTableData] = useState<ExchangeRate[]>([]);
  const { data: exchangeRatesData } = useQuery(queries.exchangeRates, {
    variables: {
      where: { storeId: { equals: boardId } },
      orderBy: { currencyCode: "asc" },
      perPage: 200,
    },
  });

  useEffect(() => {
    if (exchangeRatesData?.exchangeRates?.items) {
      setTableData(exchangeRatesData.exchangeRates.items);
    }
  }, [exchangeRatesData]);

  const duplicatedData = useMemo(
    () => [...tableData, ...tableData],
    [tableData]
  );

  return (
    <ConfigProvider
      theme={{
        token,
        components,
      }}
    >
      <Layout>
        <SEO title="DLKUB" />
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-4 bg-primary-100">
            <Button
              icon={<LeftOutlined />}
              type="link"
              className="text-white"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
            <img className="w-40" src={logo} alt="dlkub-logo" />
            <div className="w-12"></div>
          </div>
        </div>
        <main className="flex-grow p-4 overflow-hidden sm:p-6">
          <div className="flex flex-col h-full overflow-hidden bg-white shadow-lg rounded-xl">
            <div className="p-4 text-white bg-primary-100">
              <h2 className="text-2xl font-bold text-center sm:text-3xl">
                Exchange Rates
              </h2>
            </div>
            {/* Table header for desktop view */}
            <div className="hidden bg-gray-100 lg:block">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="tracking-wider text-center uppercase text-primary-100">
                    <th className="w-1/6 px-4 py-3 text-lg font-semibold sm:px-6 sm:py-4 sm:text-2xl">
                      Flag
                    </th>
                    <th className="w-1/6 px-4 py-3 text-lg font-semibold sm:px-6 sm:py-4 sm:text-2xl">
                      Currency
                    </th>
                    <th className="w-1/6 px-4 py-3 text-lg font-semibold sm:px-6 sm:py-4 sm:text-2xl">
                      Denomination
                    </th>
                    <th className="w-1/4 px-4 py-3 text-lg font-semibold sm:px-6 sm:py-4 sm:text-2xl">
                      Buy
                    </th>
                    <th className="w-1/4 px-4 py-3 text-lg font-semibold sm:px-6 sm:py-4 sm:text-2xl">
                      Sell
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="flex-grow overflow-hidden">
              <div className="h-full animate-scroll hover:pause">
                {/* Mobile header */}
                <div className="px-4 py-2 font-semibold bg-gray-100 text-primary-100 sm:hidden">
                  Currency / Denomination / Buy / Sell
                </div>
                <table className="w-full table-fixed">
                  <tbody className="bg-white divide-y divide-blue-100">
                    {duplicatedData.map((item, index) => (
                      <tr
                        key={`${item.id}-${index}`}
                        className="transition-colors duration-150 hover:bg-blue-50"
                      >
                        <td className="w-full px-4 py-3 sm:w-1/6 sm:px-6 sm:py-5 whitespace-nowrap">
                          <div className="flex items-center sm:justify-center">
                            <span
                              className={`fi fi-${item.currency.code
                                .toLowerCase()
                                .slice(
                                  0,
                                  -1
                                )} text-3xl sm:text-4xl mr-3 sm:mr-0`}
                            />
                            <div className="ml-2 sm:hidden">
                              <div className="text-lg font-semibold text-blue-900">
                                {item.currency.code}
                              </div>
                              <div className="text-sm text-blue-700">
                                {item.denomination.toLocaleString()}
                              </div>
                              <div className="text-sm">
                                <span className="mr-2 font-medium text-green-600">
                                  Buy: {item.buyRate}
                                </span>
                                <span className="font-medium text-red-600">
                                  Sell: {item.sellRate}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="hidden w-1/6 px-6 py-5 sm:table-cell whitespace-nowrap">
                          <div className="text-xl font-semibold text-center text-blue-900 sm:text-2xl">
                            {item.currency.code}
                          </div>
                        </td>
                        <td className="hidden w-1/6 px-6 py-5 sm:table-cell whitespace-nowrap">
                          <div className="text-xl text-center text-blue-700 sm:text-2xl">
                            {item.denomination.toLocaleString()}
                          </div>
                        </td>
                        <td className="hidden w-1/4 px-6 py-5 sm:table-cell whitespace-nowrap">
                          <div className="text-xl font-medium text-center text-green-600 sm:text-2xl">
                            {item.buyRate}
                          </div>
                        </td>
                        <td className="hidden w-1/4 px-6 py-5 sm:table-cell whitespace-nowrap">
                          <div className="text-xl font-medium text-center text-red-600 sm:text-2xl">
                            {item.sellRate}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </ConfigProvider>
  );
};

export default withRates(Board);
