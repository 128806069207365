// import "@lottiefiles/lottie-player";

import React from "react";

interface LayoutProps {
  children: React.ReactNode;
  location: string;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return <div>{children}</div>;
};

export default Layout;
